
.theory-container-one {
  ::v-deep .el-button--text {
    color: rgba(17, 34, 216, 1);
  }
  padding: 30px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  .theory-container-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .training-main {
      .training-title {
        margin-left: 4px;
        font-weight: bold;
      }
    }
  }
  .el-table {
    flex:1;
    margin-top:20px;
    ::v-deep .el-table th.el-table__cell {
      background: rgba(245, 245, 251, 1);
    }
    .pages-center {
      margin-top: 15px;
      text-align: center;
    }
  }
  .pages-center {
    text-align: center;
    margin-top: 20px;
  }
}
